/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, Link, graphql } from "gatsby"
import Image from "gatsby-image"

const BioLindsay = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/author-lindsay.jpg/" }) {
        childImageSharp {
          fixed(width: 90, height: 90) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  
  return (
    <div>
      <Image
        fixed={data.avatar.childImageSharp.fixed}
        alt='Lindsay Stewart'
        style={{
          marginBottom: `1rem`,
          minWidth: 90,
          borderRadius: `100%`,
        }}
        imgStyle={{
          borderRadius: `50%`,
        }}
      />
      <p>
        <small>
          Written by{" "}
          <a
            href="https://lindsaystewart.me"
            rel="noopener noreferrer"
            target="_blank"
          >
            <strong>Lindsay Stewart</strong>
          </a>
          , Developer and Technical Researcher for{" "}
          <Link to={"/"}>Glance Digital</Link>
        </small>
      </p>
    </div>
  )
}

export default BioLindsay
