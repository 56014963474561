import React from "react"
import { Link, graphql } from "gatsby"

import BioLindsay from "../components/bio-lindsay"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BlogPostModule from "../styles/blogpost.module.scss"

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata.title
  const { another } = pageContext

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />

      <section className={BlogPostModule.container}>
        <div className="grid-container grid-x grid-padding-x align-center">
          <div className="cell small-12 medium-10 large-9">
            <article>
              <header>
                <h1>{post.frontmatter.title}</h1>
                <p>{post.frontmatter.date}</p>
              </header>

              <section dangerouslySetInnerHTML={{ __html: post.html }} />

              <footer>
                <BioLindsay />
              </footer>
            </article>

            <nav>
              <h6>Read another one:</h6>
              <ul>
                <li>
                  <h4>
                    {another && (
                      <Link to={another.fields.slug} rel="prev">
                        {another.frontmatter.title}
                      </Link>
                    )}
                  </h4>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
  }
`
